.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: skyblue;
    overflow: hidden;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
}

.row > p {
    font-weight: bold;
    font-variant: small-caps;
}

.button-text {
    font-weight: bold;
    font-family: inherit;
}

.App-logo {
    height: 5vh;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
