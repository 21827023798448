.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  min-height: 100vh;
}

.center-content {
  flex: 1;
  overflow: auto;
}

div .dark-mode {
  background-color: black;
}

.dark-mode h3 {
  color: white;
}
