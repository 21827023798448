.diamond {
    align-self: center;
    width: calc(65% - 1.2vw);
    height: calc(65% - 1.2vw);
    position: relative;
    border: .6vw solid white;
    transform: rotate(45deg);
    /* overflow: hidden; */
}

/* 
.display, .display-digits {
    padding: 0px !important;
} */

.runners > div {
    transform: rotate(-45deg);
}

.bases div {
    width: 20%;
    height: 20%;
    position: absolute;
    background-color: white;
}

.second {
    top: 0;
    left: 0;
}

.first {
    top: 0;
    right: 0;
}

.third {
    bottom: 0;
    left: 0;
}

.home {
    bottom: 0;
    right: 0;
}

.circle {
    width: 18%;
    height: 18%;
    border-radius: 50%;
    position: absolute;
    background-color: black;

    display: flex;
    justify-content: center;
    align-items: center;
}

.circle > p {    
    font-size: 75%;
}

.inning-marker {
    width: min(2.5vw, 2.5vh);
    height: min(2.5vw, 2.5vh);
    position: inherit;
}

.orange {
    background-color: orange;
}

.green {
    background-color: green;
}

.red {
    background-color: red;
}

.white {
    background-color: white;
    color: black;
}

.outs > div {
    border: 2px solid white;
} 

.outs div:nth-child(1) {
    bottom: 5%;
    right: 25%;
}

.outs div:nth-child(2) {
    bottom: 25%;
    right: 5%;
}

.text {
    position: absolute;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%) rotate(-45deg);
    /* rotate text */
    font-size: 2.5vw;
    font-weight: bold;
}

.not-selectable {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}