/* display for all of the gameboards */
.scoreboard {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 25px;
}

/* individual game information */
.gameboard {
    position: relative;
    /* on desktop, this value will be 45% of the view width, spacing for 2 boards */
    /* on mobile, this value will be 90% of the view width, spacing for 1 board */
    --gameboard-width: 45vw;
    width: var(--gameboard-width);
    height: calc(var(--gameboard-width) / 3);

    color: white;
    background-color: black;
}

.gameboardContents {
    z-index: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

/* vertical flex container for pitcher numbers */
.pitcherBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

/* flex container for the diamond! this one is important */
.diamondBox {
    display: flex;
    flex: 0 0 33.33%;
    /* set size to 1/3 of the width of the gameboard */
    justify-content: center;
    align-items: center;
}

/* vertical flex container for the abbreviated names of the teams */
.teamBox {
    font-size: calc(var(--gameboard-width) / 9);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.teamBox p {
    margin: 0px;
}

/* vertical flex container for each team's score */
.scoreBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

/* vertical flex container for the inning and top/bottom lights */
.inningBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.preview {
    background: linear-gradient(
        to top right,
        transparent 0%,
        transparent 50%,
        dodgerblue 50%,
        dodgerblue 100%
    );
}

.live {
    background: linear-gradient(
        to top right,
        transparent 0%,
        transparent 50%,
        green 50%,
        green 100%
    );
}

.final {
    background: linear-gradient(
        to top right,
        transparent 0%,
        transparent 50%,
        orange 50%,
        orange 100%
    );
}

.stateIndicator {
    z-index: 1;
    position: absolute;
    right: 0px;
    top: 0px;
    aspect-ratio: 1;
    height: 10%;
}

@keyframes outline-pulsate {
    0% {
        outline-color: rgba(255,165,0, 1);
    }
    50% {
        outline-color: rgba(0, 255, 255, 0);
    }
    100% {
        outline-color: rgba(255,165,0, 1);
    }
}

.flash {
    outline: 3px solid;
    outline-color: rgba(0, 255, 255, 0);
    animation-name: outline-pulsate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
